<template>
  <main v-if="library">
    <div class="news-title">{{ library.docTitle }}</div>
    <div class="news-info">
      <span class="date"> 发布时间： {{ dateFormat(library.createTime) }}</span>
    </div>
    <div class="news-content"><div v-html="library.docContent"></div></div>
    <recommend title="推荐阅读" :list="recommendList"></recommend>

    <footer>
      <ul class="footer-nav">
        <li class="footer-li">
          <a
            href="https://tb.53kf.com/code/client/099be4cda651b04a92a215ad1e6edd8d8/1"
          >
            <!-- <img src="http://www.bncc.org.cn/static/m/images/icon52.png" alt="" /> -->
            <img src="@/assets/img/common/icon52.png" alt="" />
            客服
          </a>
        </li>
        <li class="footer-li">
          <a
            href="javascript:;"
            class="collect collect-btn"
            data-id="${(library.id)?c}"
          >
            <img
              v-if="isFav"
              src="https://beinabncc.oss-cn-beijing.aliyuncs.com/file//icon53-120210417.png"
              alt=""
            />
            <!-- src="http://www.bncc.org.cn/static/m/images/icon53_1.png" -->

            <img
              v-else
              src="https://beinabncc.oss-cn-beijing.aliyuncs.com/file//icon5320210417.png"
              alt=""
              @click="onCollect"
            />
            <!-- src="http://www.bncc.org.cn/static/m/images/icon53.png" -->

            收藏
          </a>
        </li>

        <li class="footer-li view-video" @click="download">
          <a href="javascript:;" class="download" download="">下载</a>
        </li>
      </ul>
    </footer>
  </main>
  <loading :options="loadOption" v-else></loading>
</template>

<script lang="ts">
import { defineComponent, ref, reactive,nextTick, watch } from "vue";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import qs from "qs";
import axios from "@/api/axios";
import dateFormat from "@/utils/dateFormat";
import Recommend, { RecommnedList } from "@/components/common/Recommend.vue";
import { ImgItem } from "@/components/UI/Preview/src/Preview.vue";
import { useRoute } from "vue-router";
//  components
import Loading from "@/components/UI/Loading";
import wx from "weixin-sdk-js";
export default defineComponent({
  name: "DocDetail",
  components: {
    Recommend,
    Loading,
  },
  setup() {
    const shareTitle = ref('')
    const route = useRoute();
    const id = route.query.docId;
    const library = ref<any>(null);
    const loading = ref(true);
    const isFav = ref(false);
    const recommendList = reactive<RecommnedList[]>([]);

    //  async data
    function getRenderData(id: string) {
      //  async data
      library.value = null;
      loading.value = true;
      recommendList.length = 0;
      axios
        .get("/M/Discover/LibraryDetail/" + id)
        .then((res) => {
          if (res.data.success) {
            const data = res.data.obj;
            library.value = data.library;
            isFav.value = data.isFav;
            data.list.forEach((item: any) => {
              const link = {
                name: "DocDetail",
                query: { docId: item.id },
              };
              recommendList.push({
                id: item.id,
                title: item.docTitle,
                link,
              });
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          loading.value = false;
        });
    }
    getRenderData(id as string);
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    const imgList = reactive<ImgItem[]>([]);
    function onCollect(isCollect: boolean) {
      const data = qs.stringify({
        id: (library.value as any).id,
        type: 4,
      });
      axios
        .post("/M/Server/Collection", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
            });
            isFav.value = true;
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
             console.log('%c=========','color:blue');
          console.log(err);
          // const message = err.message ? err.message : "未知错误，请联系管理员";
          // Toast({
          //   type: "error",
          //   title: message,
          // });
        });
    }
    watch(
            () => {
              return shareTitle.value
            },
            (to, from) => {
              const isweixin = ref(false);
              const ua = navigator.userAgent.toLowerCase();
              const reg = /MicroMessenger/i;
              isweixin.value = reg.test(ua);
              if (isweixin.value) {
                nextTick(() => {
                  // const imgUrl = "http://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/logo20200923.svg";
                  const link = window.location.href;
                  const title = document.title;
                  const desc = (document.querySelectorAll(
                          "meta[name=description]"
                  )[0] as any).content;
                  console.log(desc);

                  const formData = new FormData();
                  formData.append("url", link);
                  axios.post("/M/Server/getweixin", formData).then((res: any) => {
                    const data = res.data;
                    wx.config({
                      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                      appId: data.appid, // 必填，公众号的唯一标识
                      timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                      nonceStr: data.nonceStr, // 必填，生成签名的随机串
                      signature: data.str, // 必填，签名
                      jsApiList: [
                        "checkJsApi",
                        "updateAppMessageShareData",
                        "updateTimelineShareData",
                      ], // 必填，需要使用的JS接口列表
                    });
                    wx.ready(function () {
                      //需在用户可能点击分享按钮前就先调用

                      wx.updateAppMessageShareData({
                        title: to+'-北纳生物', // 分享标题
                        desc: route.meta.content.description, // 分享描述
                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                        success: function () {
                          // 设置成功
                        },
                      });
                      wx.updateTimelineShareData({
                        title: to+'-北纳生物', // 分享标题
                        desc: route.meta.content.description, // 分享描述
                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                        success: function () {
                          // 设置成功
                        },
                      });
                    });
                  });
                });
              }
            }
    )

    watch(
            () => {
              return library.value;
            },
            () => {
              nextTick(() => {
                imgList.length = 0
                const imgDom = document.querySelectorAll(".topic-content img");
                imgDom.forEach((img) => {
                  imgList.push({ src: img.getAttribute("src") as string });
                });
              });
            }
    );
    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        if (to[0].docId && !from[1].valid) {
          getRenderData(to[0].docId as string);
        }
      }
    );
    function download() {
      Modal({
        type: "none",
        title: "温馨提示",
        content: "下载请移步至电脑端：https://www.bncc.com/",
      });
    }
    return {
      dateFormat,
      library,
      loading,
      loadOption,
      recommendList,
      onCollect,
      isFav,
      imgList,
      download,
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 110px;
  text-align: center;
}

.news-title {
  color: #444444;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 20px;
}

.news-info {
  color: #999999;
  font-size: 14px;
}

.author,
.date {
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}

.count {
  margin-right: 0;
}

.news-content {
  width: 355px;

  margin: 0 auto;
  margin-top: 10px;
  padding: 0 10px;
  box-sizing: border-box;
  text-align: justify;
  font-size: 16px;
  color: #666666;
  line-height: 25px;

  border-bottom: 1px solid #eee;
  :deep(p) {
    margin: 1em 0;
    word-break: break-word;
  }
  :deep(a) {
    color: #3498db;
  }
  :deep(video) {
    width: 100% !important;
    height: auto !important;
  }
  :deep(table) {
    width: 100% !important;
  }
  :deep(img) {
    width: 100% !important;
    height: auto !important;
  }
  :deep(table) {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
  }
}

footer {
  position: fixed;
  height: 55.988px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #eee;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1px solid #f4f4f4;

  background-color: #fff;
  z-index: 100;
}
footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-li {
  float: left;
  width: 15%;
  text-align: center;
  position: relative;
}
.footer-li img {
  display: block;
  width: 20px;
  height: 20px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -9.75px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #df0024;
}

.footer-nav .active .active-status {
  display: block;
}

.view-video {
  width: 110px;
  height: 36px;
  border-radius: 18px;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  background-color: #d64b4b;
  margin-left: 6.5px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
  display: inline-block;
}
.view-video a {
  color: #fff;
  font-size: 14px;
}
</style>
